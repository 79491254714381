import React, { useState } from "react";
import { BsTelegram, BsTwitter } from 'react-icons/bs';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const onLookUp = (e) => {
    if(e.key === "Enter"){
      window.open(`/info?number=${e.target.value}`, "_blank");
    }
  }

  const onMobLookUp = (e) => {
    if (e.key === "Enter") {
      window.open(`/info?number=${e.target.value}`, "_blank");
    }
  }

  const onLookBtnClick = () => {
    let number = document.getElementById("lookup").value;
    if(number > 0){
      window.open(`/info?number=${number}`, "_blank");
    }
  }

  const onMobLookBtnClick = () => {
    let number = document.getElementById("mob_lookup").value;
    if(number > 0){
      window.open(`/info?number=${number}`, "_blank");
    }
  }

  const onWallet = (e) => {
    if(e.key === 'Enter') {
      window.open(`/wallet?address=${e.target.value}`, "_blank");
    }
  }

  const onMobWallet = (e) => {
    if (e.key === 'Enter') {
      window.open(`/wallet?address=${e.target.value}`, "_blank");
    }
  }

  const onWalletBtnClick = () => {
    let address = document.getElementById("wallet").value;
    window.open(`/wallet?address=${address}`, "_blank");
  }

  const onMobWalletBtnClick = () => {
    let address = document.getElementById("mob_wallet").value;
    window.open(`/wallet?address=${address}`, "_blank");
  }

  return (
    <div className="fixed w-full bgMain text-white z-10">
      <div className="flex flex-row w-full items-center justify-between px-5 h-[64px]">
        <div className="flex flex-row gap-x-5 items-center">
          <div className="text-xl font-bold">
            <a href="http://www.horribleheads.com">
              <img src="logo.png" width={140} />
            </a>
          </div>
          <div className="hidden md:flex flex-row items-center gap-x-5">
            <a href="https://t.me/horribleheads" target="_blank"><BsTelegram className="w-6 h-6"/></a>
            <a href="https://twitter.com/horrible_heads" target="_blank"><BsTwitter className="w-6 h-6"/></a>
            <a href="/instructions" target="_blank">Guide</a>
            <a href="/About" target="_blank">About</a>
            <a href="https://www.horribleheads.co.uk/wall_art/visualiser.html" target="_blank">WallArt</a>
            <a href="https://www.horribleheads.co.uk/4K_TEASERS.html" target="_blank">4k</a>
            <a href="https://www.horribleheads.co.uk/horribleheads_gallery/horribleheads_gallery.html" target="_blank">Anim</a>
            
            
            
          </div>
        </div>
        <div className="hidden md:flex flex-row gap-6 items-center">
          <div className="flex flex-row gap-2">
            <input className=" border-white border-[1px] text-white rounded-sm bg-transparent text-center w-16" id="lookup" placeholder="0124" onKeyPress={(e) => onLookUp(e)}></input>
            <div className=" border-black border-[1px] text-gray-700 rounded-sm bg-[#F8F8F8] text-center w-16 cursor-pointer" onClick={() => onLookBtnClick()}>lookup</div>
          </div>
          <div className="flex flex-row gap-2">
            <input className=" border-white border-[1px] text-white rounded-sm bg-transparent text-center w-16" id="wallet" placeholder="0124" onKeyPress={(e) => onWallet(e)}></input>
            <div className=" border-black border-[1px] text-gray-700 rounded-sm bg-[#F8F8F8] text-center w-16 cursor-pointer" onClick={() => onWalletBtnClick()}>wallet</div>
          </div>
        </div>
        <div className="md:hidden">
          {
            !mobileMenu ? <FaBars onClick={() => setMobileMenu(true)} className="w-5 h-5" /> : <FaTimes className='w-5 h-5' onClick={() => setMobileMenu(false)} />
          }
        </div>
      </div>
      {/* Mobile Menu */}
      {mobileMenu && 
        <div className='w-full h-full fixed top-16 z-30 mobileMenu elementFadeDown pt-8 flex flex-col items-center gap-y-4 text-xl font-medium'>
          <a href="/instructions" target="_blank">instructions</a>
          <div className="flex flex-row gap-4 mt-6">
            <input className=" border-white border-[1px] rounded-sm bg-transparent text-center w-24" id="mob_lookup" placeholder="0124" onKeyPress={(e) => onMobLookUp(e)}></input>
            <div className=" border-black border-[1px] text-gray-700 rounded-sm bg-[#F8F8F8] text-center w-24 cursor-pointer" onClick={() => onMobLookBtnClick()}>lookup</div>
          </div>
          <div className="flex flex-row gap-4 mt-4">
            <input className=" border-white border-[1px] rounded-sm bg-transparent text-center w-24" id="mob_wallet" placeholder="0124" onKeyPress={(e) => onMobWallet(e)}></input>
            <div className=" border-black border-[1px] text-gray-700 rounded-sm bg-[#F8F8F8] text-center w-24 cursor-pointer" onClick={() => onMobWalletBtnClick()}>wallet</div>
          </div>
          <div className="flex flex-row items-center justify-center gap-x-8 mt-8">
            <a href="https://t.me/" target="_blank"><BsTelegram className="w-6 h-6"/></a>
            <a href="https://twitter.com/" target="_blank"><BsTwitter className="w-6 h-6"/></a>
          </div>
        </div>
      }
    </div>
  )
}

export default Header;