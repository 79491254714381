import axios from "axios";
import {
  camelCase,
  shuffle
} from "lodash";
const BASE_URL = 'https://vikingland-server-new.vercel.app/api/nftsCollection/Horrible%20Heads/All/empty/Price%20low%20to%20high/';

export const loadMetaData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_METADATA' });
    
    function onSuccess(success) { 
      let rawData = success.data.values; 
      let keys = rawData[0].slice(0, rawData[0].length);
      let metaData = {};
      let traits = {};
      let maininfo = [];
      rawData.forEach((o, idx) => {
        if (idx > 0) { 
          let item = {};
          keys.forEach((key, kIdx) => { 
            item[camelCase(key)] =  kIdx < o.length ? o[kIdx] : '';
            // if(kIdx > 0 && key.toLowerCase().search('COLOUR'.toLowerCase()) === -1){
            if(kIdx > 0 && key.toLowerCase().search('EYES'.toLowerCase()) === -1){
              if(traits[camelCase(key)] == undefined && (o[kIdx] != undefined && o[kIdx].length > 1)){
                traits[camelCase(key)] = [];
                traits[camelCase(key)][0] = o[kIdx];
              } else if((o[kIdx] != undefined && o[kIdx].length > 1)){
                if(traits[camelCase(key)].indexOf(o[kIdx]) <= -1){
                  traits[camelCase(key)].push(o[kIdx]);
                }
              }
            }
          });
          maininfo.push(item);
        }
      });
      
      maininfo = shuffle(maininfo);
      maininfo.sort((a, b) => parseInt((a.horribleHeads.split("_")[2]).split(".")[0]) - parseInt((b.horribleHeads.split("_")[2]).split(".")[0]));
      metaData[camelCase("maininfo")] = maininfo;
      metaData[camelCase("traits")] = traits;
      
      dispatch({ type: 'LOAD_METADATA', payload: metaData });
      return metaData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1XnMabCaxAKNLevImoCQD5gs05gcJD4_Y7leDU36fkUU/values/HORRIBLE_HEADS?alt=json&key=AIzaSyCiqck4CSTHD7FEI4YLFTkEL6CTahIjhMU');
      // const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1DHqIXQl1x7P3j9crJGKwi8X6Lfj1lrDVPfW9Ib2Z_qY/values/INVADERZ?alt=json&key=AIzaSyA0SH9dMT670G7180Qj0D4ry3iEN_o22Rc');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadRarityData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_RARITYDATA' });

    function onSuccess(success) { 
      let rawData = success.data.values;
      let keys = [];
      let rarityData = {};
      rawData.map((item, index) => { 
        if (index > 0) { 
          if(rawData[index - 1].length === 0) {
            keys.push(camelCase(item[0]));
          }
        }
      });
      
      rawData.map((item, index) => {
        if (keys.includes(camelCase(item[0])) && index > 0 && rawData[index - 1].length === 0) {
          item.map((ele, ind) => {
            if (ind > 0) {
              let itemat = {};
              itemat[camelCase("name")] = ele;
              itemat[camelCase(rawData[index + 1][0])] = rawData[index + 1][ind];
              itemat[camelCase(rawData[index + 2][0])] = rawData[index + 2][ind];
              itemat[camelCase(rawData[index + 3][0])] = rawData[index + 3][ind];
              if (rarityData[camelCase(item[0])] == undefined) {
                rarityData[camelCase(item[0])] = [];
                rarityData[camelCase(item[0])][0] = itemat;
              } else {
                rarityData[camelCase(item[0])].push(itemat);
              }
            }
          });
        }
      });
      
      dispatch({ type: 'LOAD_RARITYDATA', payload: rarityData });
      return rarityData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1XnMabCaxAKNLevImoCQD5gs05gcJD4_Y7leDU36fkUU/values/RARITY?alt=json&key=AIzaSyCiqck4CSTHD7FEI4YLFTkEL6CTahIjhMU&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadTokenListByOffset = (offset) => { 
  return async (dispatch, getState) => {
    dispatch({type: 'START_LOAD_TOKENLIST'});
    let res = [];
    fetch(`https://vikingland-server-new.vercel.app/api/nftsCollection/Horrible%20Heads/All/empty/Price%20low%20to%20high/${offset}`, { method: 'get' })
    .then(response => response.json())
    .then(data => {
      for (const [key, value] of Object.entries(data)) {
        res[parseInt(key - offset)] = value;
      }
      res = getState().common.tokenlist.concat(res);
      
      dispatch({type: 'LOAD_TOKENLIST', payload: res});
      return res;
    });
  }
};

export const loadTokenListByWallet = (wallet) => {
  return async (dispatch) => {
    dispatch({type: 'START_LOAD_WALLETTOKEN'});
    let res = [];
    fetch(`https://vikingland-server-new.vercel.app/api/userNfts/${wallet}/Horrible%20Heads/All`, { method: 'get' })
    .then(response => response.json())
    .then(data => {
      if (data.status != 'failed') {
        let i = 0;
        for (const [key, value] of Object.entries(data)) {
          res[i++] = value;
        }
        dispatch({type: 'LOAD_WALLETTOKEN', payload: res});
        return data;
      }
      dispatch({type: 'LOAD_WALLETTOKEN', payload: res});
      return null;
    })
  }
};

export const loadTokenByID = (id) => {
  return async dispatch => {
    dispatch({type: 'START_LOAD_TOKEN'});
    fetch(`https://vikingland-server-new.vercel.app/api/collection/Horrible%20Heads/Horrible%20Head%20${id}`)
	  .then(response => response.json())
	  .then(data => {
      dispatch({type: 'LOAD_TOKEN', payload: data});
      return data;
    });
  }
}

export const loadTotalSold = () => {
  return async dispatch => {
    dispatch({type: 'START_LOAD_SOLD'}); 
    const res = await axios.get("https://vikingland-server-new.vercel.app/api/Reserved/Horrible%20Heads/rdx1qspctzmkl43f6teedl29uv39ktwecaaxcm4ex0ppf2qjeajju8ksw4gsehhds");
    dispatch({type: 'LOAD_SOLD', payload: res.data.Reserved
});
  }
}