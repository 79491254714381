import { HiOutlineMail } from 'react-icons/hi';
import { FaTelegram, FaTwitter } from 'react-icons/fa';
import $ from 'jquery';

$(function () {
    $("#stringMe").on("click", function () {
        var trade = $("#trade");
        var number = $("#number");
        var amount = $("#amount");
        var wall = $("#wall");
        var string = "<p style='word-break: break-all;' id='copytx'>" + number.val() + " for " + amount.val() + " xrd to " + wall.val() + "</p>";
        $(string).appendTo("#stringedUp");
    });
});

function copyToClipboard2(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
}

const Instruction = () => {
    return (
        <div className="text-white w-full flex justify-center py-5">
            <div className="md:w-[800px] w-full md:px-600 px-5 text-center">
                <div className="uppercase text-3xl">HORRIBLE HEADS</div>
                <div className="mt-10">
                    <div className="text-2xl">Instructions for HORRIBLE HEADS</div>
                    <br />
                    <a href="https://www.vikingland.io/collection/Horrible%20Heads" target="_blank" className="break-words">For sales please visit <b>Radish Square</b></a>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <br /> HORRIBLE HEADS CONSIST OF 6666 UNIQUE NFTS for minting on the Radix DLT.<br />
                        <a href="https://radishsquare.com/" target="_blank" className="break-words">Please goto <b>Radish Square</b> to purchase. </a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">DISCLAIMER</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        The HORRIBLE HEADS Team will NOT be held responsible if your transactions are incorrect.
                        <br />
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">FIND US HERE FOR VERIFICATION:</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <a href="https://explorer.radixdlt.com/#/accounts/rdx1qspctzmkl43f6teedl29uv39ktwecaaxcm4ex0ppf2qjeajju8ksw4gsehhds" target="_blank" className="break-words">Horrible Heads Collection Wallet</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">USE THE OFFICIAL RADIX DLT WALLET HERE:</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <a href="https://wallet.radixdlt.com" target="_blank" className="break-words">https://wallet.radixdlt.com</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">Only use Radix's Native Token (XRD):</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <div>The official Radix Wallet does NOT accept ethereum wrapped Radix Tokens (EXRD).</div>
                        <a href="https://learn.radixdlt.com/article/how-can-i-purchase-xrd-and-exrd-tokens" target="_blank" className="break-words">https://learn.radixdlt.com/article/how-can-i-purchase-xrd-and-exrd-tokens</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">Reach Us:</div>
                    <div className="mt-5 text-slate-300 flex flex-row justify-center gap-10 text-3xl">
                        <a href='https://t.me/horribleheads' target="_blank"><FaTelegram /></a>
                        <a href="https://twitter.com/horrible_heads" target="_blank"><FaTwitter /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Instruction;
