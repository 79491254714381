import { HiOutlineMail } from 'react-icons/hi';
import { FaTelegram, FaTwitter } from 'react-icons/fa';
import $ from 'jquery';

$(function () {
    $("#stringMe").on("click", function () {
        var trade = $("#trade");
        var number = $("#number");
        var amount = $("#amount");
        var wall = $("#wall");
        var string = "<p style='word-break: break-all;' id='copytx'>" + number.val() + " for " + amount.val() + " xrd to " + wall.val() + "</p>";
        $(string).appendTo("#stringedUp")
    })
});

function copyToClipboard2(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove()
}
const Instruction = () => {
    return (
        <div className="text-white w-full flex justify-center py-5">
            <div className="md:w-[800px] w-full md:px-600 px-5 text-center">
                <div className="uppercase text-3xl">Concept</div>
                <div className="mt-10">
                    <div className="text-2xl">The projects concept is...</div>
                    <div className="mt-5 text-sm text-left text-slate-300 leading-6">
                        
                        <br />

                        Based on how nature overrides a host, that being of a human, animal or plant based material. 
                        A parasite is an organism that lives on or in a host organism and gets its food from or at the expense of its host. <br />
                        Equally this could also be the larva eggs of a fly feasting on fermented, rotten and decaying carcasses, dead or alive. <br /><br />
                        The project is not for the faint hearted and is very much exaggerated with some connotation of fantasy and horror. <br /><br />
                        The style was trying to simulate renaissance portraits from the 14th, 15th, and 16th centuries in Europe, simplistic and complex details in the array of images can be present, 
                        but a common theme throughout can be seen. Potentially controversial, a talking point for sure! <br /><br />
                        We grant full non-commercial rights. This means you can use the images for personal use (PFP, background image, personal blog, computer wallpaper, tattoo etc.) as long as it is not for commercial use for quality purposes. 
                        Cannot be duplicated or used for re-sale unless its a NFT trade that you have purchased.
                        <br /><br />
                    
                        <div className="text-2xl"> <b>The Ambition (Phase 1)</b></div>
                         
                        Currently we are animating 666 nfts from of the collection using AI and various animation techniques.
                        
                        We have also been working on a Telegram Bot with various horror stories that you as users can play through the chat individually or as a group. This is great for gamification where we can create leaderboards and promotional content throughout the ecosystem.
                        
                        </div>
                    
                </div>
                <div className="mt-10">
                    <div className="text-2xl">Transparency</div>
                    <div className="mt-5 text-sm text-left text-slate-300 leading-6">
                        
                        <br />
                        
                        These are AI Generated Images, which have had extensive algorithmic learning, with weeks of testing until the concept was finalised for consistency. <br /><br />
                        We took 2 months to render all the images, and 3 months of photoshop work (2 people) with 3 iterations to remove irrelevant artifacts. 
                        Additionally we have created 4k NFTs for the project with AI upscaling processes to obtain the highest of quality.<br /><br />

                        In addition we have have tested upto 100cm x 100cm upscaling techniques for Canvas Printing and are very happy with the results. 
                        Each NFT Trait (6666 in total) has been manually processed with the project taking over 10 months to do (still ongoing), with a team of 3 people.  
                        </div>
                    
                    
                    
                </div>
                <div className="mt-10">
                    <div className="text-2xl">Reach Us:</div>
                    <div className="mt-5 text-slate-300 flex flex-row justify-center gap-10 text-3xl">
                        <a href='https://t.me/horribleheads' target="_blank"><FaTelegram /></a>
                        <a href="https://twitter.com/horrible_heads" target="_blank"><FaTwitter /></a>
                        <a href='mailto:rotten@horribleheads.co.uk'><HiOutlineMail /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Instruction;